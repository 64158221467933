<template>
	<div id="xskTreeTransfer">
		<div class="transferTreeBox">
			<!-- 左侧待选内容 -->
			<div class="SelectBox">
				<div class="boxTitle" @click="clickAllSelect">全选 &gt;</div>
				<div class="boxCenter">
					<el-tree
						style="min-height: 238px;"
						ref="beforeTree"
						:data="cascaderData"
						:props="defaultProps"
						show-checkbox
						:filter-node-method="beforeFilterNode"
						:accordion="true"
						node-key="nodeCode"
					/>
				</div>
			</div>

			<!-- 中间穿梭按钮 -->
			<div class="transferBtn">
				<div class="pickBtn" @click="towardsRight">&gt;&gt;</div>
				<div class="pickBtn" @click="towardsLeft">&lt;&lt;</div>
			</div>

			<!-- 右侧已选内容 -->
			<div class="SelectBox">
				<div class="boxTitle" @click="clickCancelAllSelect">&lt; 取消全选</div>

				<div class="boxCenter">
					<el-tree ref="afterTree" style="min-height: 238px;" :data="cascaderData" :props="defaultProps" show-checkbox :filter-node-method="afterFilterNode" node-key="nodeCode" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { eltips } from '@/util/util.js';
export default {
	name: 'xskTreeTransfer',
	props: ["cascaderData","defaultProps"], // 树形结构数据
	data() {
		return {
			beforeKeyarr: [],
			afterKeyarr: []
		};
	},
	mounted() {
		// 打开弹窗清空右侧树形框
		this.$refs.afterTree.filter();
	},
	methods: {
		// 将组件的默认选中清除
		keyClear(str) {
			// 首先获取node
			console.log(str,this.$refs[str].getNode(this.cascaderData[0]))
			let node = this.$refs[str].getNode(this.cascaderData[0].nodeCode).parent;

			this.clearClickRecursion(node.childNodes);
		},
		// 清除树形默认选中的层级递归
		clearClickRecursion(data) {
			data.forEach(val => {
				val.checked = false;
				if (val.childNodes && val.childNodes.length > 0) {
					this.clearClickRecursion(val.childNodes);
				}
			});
		},
		// 点击向右穿梭
		towardsRight() {
			let currentBeforeKeyarr = this.$refs.beforeTree.getCheckedNodes(true);

			// ES6语法.清除数组内相同的对象
			let arr = this.beforeKeyarr.filter(item => !currentBeforeKeyarr.some(ele => ele.value === item.value));

			this.beforeKeyarr = arr;

			this.beforeKeyarr = [...this.beforeKeyarr, ...currentBeforeKeyarr];

			this.shuttle();
		},
		// 点击向左穿梭
		towardsLeft() {
			this.afterKeyarr = this.$refs.afterTree.getCheckedNodes(true);

			let arr = this.beforeKeyarr.filter(item => !this.afterKeyarr.some(ele => ele.value === item.value));

			this.beforeKeyarr = arr;

			this.shuttle();
		},
		// 穿梭
		async shuttle() {
			let str = '';

			this.beforeKeyarr.forEach(item => {
				if (str) {
					str = `${str},${item.value}`;
				} else {
					str = item.value;
				}
			});

			this.keyClear('beforeTree');
			this.keyClear('afterTree');

			await this.$refs.beforeTree.setCheckedKeys([]);
			await this.$refs.afterTree.setCheckedKeys([]);

			this.$refs.beforeTree.filter(str);
			this.$refs.afterTree.filter(str);

			this.$emit('getcascaderlist', this.beforeKeyarr);
		},
		// 过滤
		beforeFilterNode(value, data) {
			if (!value) {
				return true;
			}

			return value.indexOf(data.value) < 0;
		},
		afterFilterNode(value, data) {
			if (!value) {
				return false;
			}

			return value.indexOf(data.value) !== -1;
		},
		// 点击全选
		clickAllSelect() {
			this.$refs.beforeTree.setCheckedNodes(this.cascaderData);

			this.towardsRight();
		},
		// 点击取消全选
		clickCancelAllSelect() {
			this.$refs.afterTree.setCheckedNodes(this.cascaderData);

			this.towardsLeft();
		}
	}
};
</script>

<style scoped lang="less">
#xskTreeTransfer {
	.transferTreeBox {
		display: flex;
		width: 590px;
		justify-content: space-around;

		.SelectBox {
			border: 1px solid #ccc;
			height: 270px;
			width: 200px;
			color: #fff;
			position: relative;
			.boxTitle {
				background: #a0cfff;
				height: 30px;
				line-height: 30px;
				text-align: center;
				border-bottom: 1px solid #ccc;
				cursor: pointer;
			}

			.boxCenter {
				height: 100%;
				width: 100%;
				max-height: 239px;
				overflow-y: scroll;
			}
		}

		.transferBtn {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.pickBtn {
				height: 30px;
				width: 50px;
				background: #a0cfff;
				color: #fff;
				font-weight: 700;
				font-size: 20px;
				border-radius: 5px;
				margin-top: 10px;
				text-align: center;
				line-height: 30px;
				cursor: pointer;
			}
		}
	}
}
</style>
