import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect:'/login'
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/login/login.vue'),
		meta:{
			layout:false
		}
	}
	// {
	// 	path: '/payapps',
	// 	name: 'payapps',
	// 	component: () => import('@/views/payapps/payapps.vue'),
	// 	meta:{
	// 		layout:'Layout'
	// 	}
	// },
]

const createRouter = () => new VueRouter({
	mode: 'history',
	routes
})

const router = createRouter()

//写一个重置路由的方法，切换用户后，或者退出时清除动态加载的路由
export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // 新路由实例matcer，赋值给旧路由实例的matcher，（相当于replaceRouter）
}

export default router
