import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import ImStatus from './modules/ImStatus';
import conversation from './modules/conversation';
import group from './modules/group';
import user from './modules/user';
import global from './modules/global';

export default new Vuex.Store({
  state: {
		is_apply:0,
		applyLogin:1,
		userlogin:1,
		asideWidth:0,
		routeadds:0,
		pluginroute:0,
		sdk_ready: false,
		wechatCode:null,
		bindCode:null,
  },
  mutations: {
	  //绑定微信Code
	  changeBindCode(state, bindCode) {
	  	state.bindCode = bindCode
	  },
	  //扫码登录Code
	  changeWechatCode(state, wechatCode) {
	  	state.wechatCode = wechatCode
	  },
	  //腾讯im SDK状态
	  changeSdkReady(state, sdk_ready) {
	  	state.sdk_ready = sdk_ready
	  },
	  //切换登陆、注册
	  changeIsApply(state,is_apply){
		state.is_apply = is_apply  
	  },
	  changeuserlogin(state,userlogin){
		  state.userlogin = userlogin
	  },
	  //切换登陆、注册
	  changeApplyLogin(state,applyLogin){
		  state.applyLogin = applyLogin
	  },
	  //二级菜单
	  changeasideWidth(state,asideWidth){
	  		  state.asideWidth = asideWidth
	  },
	  //路由是否加载
	  changeRouteadds(state,routeadds){
		  state.routeadds = routeadds
	  },
	  //插件路由
	  pluginrouteadd(state,routeid){
		  state.pluginroute = routeid
	  }
  },
  actions: {
  },
  modules: {
	  ImStatus,
	  conversation,
	  group,
	  user,
	  global
  }
})
