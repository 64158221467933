<!--
showlogs.sync 控制插件显示 Boolean
@confirm 父组件获取回传信息 

插件依赖 element-ui
线上客Saas组件库 商品发货 --- 2020.10.14  
 -->

<template>
	<div id="xskLogistics">
		<el-dialog title="订单发货" :visible.sync="showlogs" width="700px" :close-on-click-modal="false" :show-close="false">
			<div class="expressbox" v-loading="loading">
				<div class="textbox">
					<div class="label">收货人姓名：</div>
					<div class="values">{{userdata.name}}</div>
				</div>
				<div class="textbox">
					<div class="label">联系方式：</div>
					<div class="values">{{userdata.phone}}</div>
				</div>
				<div class="textbox">
					<div class="label">收货地址：</div>
					<div class="values">{{userdata.address}}</div>
				</div>
				<div class="textbox">
					<div class="label">快递公司：</div>
					<div class="values">
						<el-select style="width: 200px;" v-model="ship_company_id" filterable placeholder="请选择">
						    <el-option v-for="item in expresslist" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
				</div>
				<div class="textbox">
					<div class="label">快递单号：</div>
					<div class="values">
						<el-input v-model="ship_number" placeholder="请输入"></el-input>
					</div>
				</div>
			</div>
			<span slot="footer">
				<el-button size="medium" type="primary" plain @click="hidelogs">取 消</el-button>
				<el-button size="medium" type="primary" @click="confirm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { eltips } from '@/util/util.js';
export default {
	name: 'xskLogistics',
	data() {
		return {
			loading:false,
			expresslist:[], //快递公司列表
			ship_company_id:'',
			ship_number:'',
		};
	},
	created() {
		this.getlogslist()
	},
	props: {
		//控制插件显示 
		showlogs: {
			type: Boolean,
			default: false
		},
		//订单信息
		userdata:{
			type: Object,
			default:()=>{},
		},
	},
	watch:{
		//插件显示监听 显示时禁止页面滚动
		showlogs(val) {
			let preD = function(e) {
				e.preventDefault();
			};
			if (val) {
				document.body.style.overflow = 'hidden';
				document.addEventListener('touchmove', preD, { passive: false });
			} else {
				document.body.style.overflow = ''; // 出现滚动条
				document.removeEventListener('touchmove', preD, { passive: false });
			}
		},
		userdata: {
			handler(val) {
				this.ship_company_id=''
				this.ship_number=''
			},
			immediate: true
		}
	},
	methods:{
		//获取快递公司列表
		getlogslist(){
			this.loading=true
			this.axios.get('/api/periphery/get_express').then(res=>{
				this.loading=false
				if(res.code==200){
					this.expresslist = res.data
				}else{
					eltips(res.msg,'error')
				}
			}).catch(err=>{console.log(err)})
		},
		//关闭组件
		hidelogs(){
			this.$emit('update:showlogs',false)
		},
		//回传数据
		confirm(){
			if(!this.ship_company_id){
				eltips('请选择快递公司','error')
				return
			}
			if(!this.ship_number){
				eltips('请输入快递单号','error')
				return
			}
			
			let obj = {
				ship_company_id:this.ship_company_id,
				ship_number:this.ship_number
			}
			this.$emit('confirm',obj)
		},
	}
};
</script>


<style>
	#xskLogistics .el-dialog__body{
		border-top: 1px solid #e9edef;
		padding-top: 10px !important;
	}
	#xskLogistics .el-dialog__footer{
		border-top: 1px solid #e9edef;
		padding: 10px 20px 10px !important;
	}
	#xskLogistics .el-dialog__title{
		font-weight: bold;
	}
</style>
<style scoped lang="less">
#xskLogistics{
	.expressbox{
		.textbox{
			display: flex;
			margin-bottom: 20px;
			&:last-child{
				margin-bottom: 0;
			}
			.label{
				width: 90px;
				text-align: right;
			}
			.values{
				min-width: 200px;
			}
		}
	}
}


</style>
