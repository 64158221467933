import router from './index.js'
import store from '../store/index.js'
import {changeroute} from './routerPermission.js'
import {timLogin} from '@/util/tim.js'
import { eltips } from '@/util/util';
import axios from 'axios'

const whitelist = ['/login']

function getMenu(){
	return new Promise((resolve, reject)=> {
		axios.get('/api/get_menu').then(res=>{
			if(res.code==200){
				resolve(res.data)
			}else{
				resolve([])
			}
		})
	})
}

function getUserinfo(){
	return new Promise((resolve, reject)=> {
		axios.get('/api/me',{params:{guard_name:'main_admin'}}).then(res => {
			if (res.code == 200) {
				sessionStorage.setItem('userinfo', JSON.stringify(res.data));
				resolve()
			} else {
				eltips(res.msg, 'error');
				reject()
			}
		});
	})
}

router.beforeEach(async (to, from, next) => {
	let token = sessionStorage.getItem('token') 
	if(to.path=='/login'&&to.query.code){
		store.commit('changeWechatCode', to.query.code)
		next({path: '/login',replace:true});
	}
	
	let userinfo=JSON.parse(sessionStorage.getItem('userinfo'))
	let IMtoken=sessionStorage.getItem('IMtoken')
	
	if(!store.state.sdk_ready && IMtoken && userinfo){
		timLogin() 
	}
	
	if(token){
		let routeadds = store.state.routeadds
		if(routeadds==0){
			await getUserinfo()
			let data = await getMenu()
			// changeroute(data).forEach(item=>{
			// 	router.addRoute(item);
			// })
			router.addRoutes(changeroute(data))
			next({...to,replace:true})
		}else{
			if(to.query.code&&to.query.state=='bindWechatCode'){
				store.commit('changeBindCode', to.query.code)
			}
			next()
		}
	}else{
		if(whitelist.indexOf(to.path)!=-1){
			next()
		}else{
			next({path: "/login",replace:true});
		}
	}
});
