<template>
	<div id="layout" style="position: relative;">
		<div v-if="$route.meta.layout&&$route.meta.layout!=0" style="position: relative;">
			<div>
				<headernav class="fixed"></headernav>
				<div class="leftbox" ref="leftbox">
					<asidenav></asidenav>
				</div>
				<div class="rightbox" :style="{'margin-left':leftmargin+'px',width:'calc(100% - '+leftmargin+'px)'}">
					<mainbox class="main"></mainbox>
				</div>
			</div>
			
		</div>
		
		<router-view v-else/>
	</div>
</template>

<script>
import headernav from './components/headernav.vue'
import asidenav from './components/asidenav.vue'
import mainbox from './components/mainbox.vue'
import watermark from '@/util/watermark.js'
export default {
	name: 'layout',
	components:{headernav,asidenav,mainbox},
	data() {
		return {
			leftmargin:100,
		};
	},
	created() {
		if(this.$route.matched.length>1){
			this.leftmargin=260
		}else{
			this.leftmargin=110
		}
	},
	mounted(){
		const route = this.$route;
		if(route.path!='/login'&&route.path!='/orangelogin'){
			const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
			if(userinfo) watermark.set(userinfo.guard_name=='api'?'总后台':userinfo.username,userinfo.guard_name=='api'?userinfo.shop_name:userinfo.phone);
		}else{
			watermark.close()
		}
	},
	watch:{
		'$route':function(route){
			if(route.path!='/login'&&route.path!='/orangelogin'){
				const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
				if(userinfo) watermark.set(userinfo.guard_name=='api'?'总后台':userinfo.username,userinfo.guard_name=='api'?userinfo.shop_name:userinfo.phone);
			}else{
				watermark.close()
			}
		},
		'$store.state.asideWidth':function(newflag,oldflag){
			if(newflag==50){
				this.leftmargin=250
				
			}else{
				this.leftmargin=100
			}
		},
	},
	mounted() {
		
	},
}
</script>

<style lang="less" scoped>
	.leftbox{
		display:inline-block;
		vertical-align: top;
		min-width: 100px;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 999;
		mine-height: 100vh;
		background: #1C2C3C;
	}
	.rightbox{
		// float: left;
		margin-left: 100px;
		display:inline-block;
		vertical-align: top;
		position: relative;
		min-height: 100vh;
	}
	.fixed{
		width: calc(100%);
		position: fixed;
		left: 0;
		top: 0;
		z-index: 1001;
	}
	.main{
		position: relative;
		top: 50px;
	}
	.rightsty{
		position: fixed;
		right: 10px;
		top: 60px;
	}
	
</style>
