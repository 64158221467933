<template>
	<div class="menu" ref="menuref">
		<div class="list">
			<div class="logbox">
				<el-image :src="require('assets/login/logo.png')" fit="cover" class='image'></el-image>
			</div>
			<div class="menu-item" v-for="(item,index) in meun" :key="index" :class="{'active':item.meta.router_name==$route.meta.router_name}" @click="meunlink(item)">
				<!-- <svg-icon v-if="item.meta.router_name==$route.meta.router_name" :icon-class="item.meta.icon+'s'" style="margin-right: 3px;font-size: 16px;position: relative;top: 2px;"></svg-icon> -->
				<!-- <svg-icon v-else :icon-class="item.meta.icon" style="margin-right: 3px;font-size: 16px;position: relative;top: 2px;"></svg-icon> -->
				{{item.meta.title}}
				<span v-if="item.name=='communicate'&&totalUnreadCount>0" class="red">
					{{totalUnreadCount>99?'99+':totalUnreadCount}}
				</span>
			</div>

		</div>
		<div class="morelist" v-if="list.length>0">
			<div class="text" v-for="(nav,i) in list" :key="i" @click="navpath(nav,i)">
				<div class="titles" :class="{'navactive':nav.path==$route.path,'towmenu':!nav.path}">
					<span>{{nav.meta.title}}</span>
					<span class="icons" :class="{'transition':childindex==i}"
						v-if="nav.children&&nav.children.length>0">
						<i class="el-icon-arrow-right"></i>
					</span>
				</div>

				<transition-group class="test">
					<div v-show="childindex==i" class="text" v-for="(children,children_i) in nav.children" :key="'leveltwo'+children_i"
						@click.stop="threepath(children,children_i)">
						<div class="titles" style="padding-left: 28px;"
							:class="{'navactive':children.path==$route.path,'towmenu':!children.path}">
							<span>{{children.meta.title}}</span>
							<span class="icons" :class="{'transition':child2index==children_i}"
								v-if="children.children&&children.children.length>0">
								<i class="el-icon-arrow-right"></i>
							</span>
						</div>
					
						<transition-group>
							<div v-show="child2index==children_i" style="padding-left: 36px;" class="childtext"
								:class="{'navactive':children2.path==$route.path}"
								v-for="(children2,children_i2) in children.children" :key="'levelthree'+children_i2"
								@click.stop="threepath(children2)">
								{{children2.meta.title}}
							</div>
						</transition-group>
					
					</div>
				</transition-group>
			</div>
		</div>
	</div>
</template>

<script>
	import { resetRouter } from '@/router/index.js'
	import store from '@/store/index.js'
	import router from '@/router/index.js'
	import { changeroute } from '@/router/routerPermission.js'
	import { mapGetters, mapState } from 'vuex'
	export default {
		name: 'asidenav',
		data() {
			return {
				meun: [],
				list: [],
				childindex: 0,
				child2index: 0,
				userinfo: JSON.parse(sessionStorage.getItem('userinfo'))
			};
		},
		computed: {
			...mapGetters({
				totalUnreadCount: 'totalUnreadCount',
			}),
			...mapState({
				num: state => state.ImStatus.totalNum,
			})
		},
		created() {
			let routes = JSON.parse(sessionStorage.getItem('routestext'))
			this.meun = this.recastRoute(routes)
			console.log(this.meun)
			// routes.forEach(item => {
			// 	if (item.meta.layout == 1) {
			// 		this.meun.push(item)
			// 	}
			// })
			this.children_route()
			this.gettotal()
		},
		watch: {
			'$route': function(newflag, oldflag) {
				this.children_route()
			}
		},
		methods: {
			recastRoute(arr){
				let newArr = []
				arr.forEach(item => {
					if (item.children&&item.children.length>0){
						item.children = this.recastRoute(item.children)
					}
					if (item.meta.layout == 1) {
						newArr.push(item)
					}
				})
				return newArr
			},
			calclayout(arr) {
				let count = 0
				arr.forEach(item => {
					if (item.layout == 1) {
						count++
					}
				})
				if (count > 1) {
					return true
				} else {
					return false
				}
			},
			//监控子路由
			children_route() {
				let that = this;
				let routes = this.meun
				if (this.$route.matched.length > 1) {
					routes.forEach(item => {
						if (item.meta.title == that.$route.matched[0].meta.title) {
							that.list = []
							item.children.forEach(child => {
								that.list.push(child)
							})
						}
					})
					if (that.list.length > 0) {
						this.$store.commit('changeasideWidth', 50)
					} else {
						this.$store.commit('changeasideWidth', 0)
					}
				} else {
					this.list = []
					this.$store.commit('changeasideWidth', 0)
				}
			},
			//跳转
			meunlink(item) {
				this.gettotal()
				if (item.path) {
					if (item.path != this.$route.path) {
						let userinfo = JSON.parse(sessionStorage.getItem('userinfo')) || ''
						let unitid = userinfo.unitid || ''
						this.$router.push({
							path: item.path,
							query: {
								props: item.meta.props
							}
						})
						this.$store.commit('changeasideWidth', 0)
					}
				} else {
					if (item.children[0].path != this.$route.path) {
						for (let i = 0; i < item.children.length; i++) {
							let child = item.children[i]
							if (child.meta.layout == 1) {
								if (child.children) {
									this.childindex = 0
									if (child.children[0].path == this.$route.path) {
										return
									}

									if (child.children[0].meta.props) {
										this.$router.push({
											path: child.children[0].path,
											query: {
												props: child.children[0].meta.props
											}
										})
										return
									} else {
										this.$router.push({
											path: child.children[0].path
										})
										return
									}
								} else {
									this.childindex = -1
									if (child.meta.props) {
										this.$router.push({
											path: child.path,
											query: {
												props: child.meta.props
											}
										})
									} else {
										this.$router.push({
											path: child.path
										})
									}
									this.$store.commit('changeasideWidth', 50)
									return
								}
							}
						}
						this.$store.commit('changeasideWidth', 0)
					}
				}
			},
			//二级菜单跳转
			navpath(item, index) {
				if (item.children) {
					if (this.childindex == index) {
						this.childindex = -1
					} else {
						this.childindex = index
					}
				} else {
					this.childindex = -1
					if (item.path && item.path != this.$route.path) {
						if (item.meta.props) {
							this.$router.push({
								path: item.path,
								query: {
									props: item.meta.props
								}
							})
						} else {
							this.$router.push({
								path: item.path
							})
						}
					}
				}
			},

			gettotal() {
				return
				this.axios.get("/api/orange_manage/chat/unreadNum").then((res) => {
					this.$store.commit('ImStatus/changetotal', res.data)
				});
			},

			//三级菜单跳转
			threepath(item, index) {
				if (item.children && (index || index === 0)) {
					if (this.child2index == index) {
						this.child2index = -1
					} else {
						this.child2index = index
					}
				} else {
					if (item.path && item.path != this.$route.path) {
						if (item.meta.props) {
							this.$router.push({
								path: item.path,
								query: {
									props: item.meta.props
								}
							})
						} else {
							this.$router.push({
								path: item.path
							})
						}
					}
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.test{
		height: 0;
		overflow: hidden;
	}
	
	.menu {
		position: relative;
		// max-width: 200px;
		height: 100vh;
		z-index: 999;

		.list {
			display: inline-block;
			vertical-align: top;
			min-width: 100px;
			background: #1989fa;
			height: 100vh;
			overflow-y: auto;

			// padding-top: 10px;
			// position: fixed;
			// left: 0;
			// top: 0;
			.logbox {
				width: 100px;
				height: 49px;
				text-align: center;
				color: #FFFFFF;
				// line-height: 49px;
				font-size: 12px;
				font-weight: bold;
				display: flex;
				align-items: center;
				justify-content: center;

				.image {
					width: 40px;
					height: 40px;
					margin-right: 4px;
				}
			}

			.menu-item {
				&:nth-child(2) {
					// border-top: 1px solid #222;
				}

				width: 100px;
				line-height: 56px;
				font-size: 14px;
				font-weight: bold;
				color: #ffffff;
				cursor: pointer;
				padding-left: 14px;
				text-align: left;
				position: relative;
				.red {
					position: absolute;
					right: 6px;
					top: 4px;
					color: #ffffff;
					background-color: #F56C6C;
					padding: 0 8px;
					// width: 50px;
					height: 18px;
					line-height: 18px;
					font-size: 12px;
					box-sizing: border-box;
					border-radius: 16px;
				}
			}

			.active {
				background: #ffffff;
				color: #1989fa !important;
			}
		}

		.morelist {
			display: inline-block;
			vertical-align: top;
			// position: absolute;
			padding-top: 50px;
			width: 150px;
			height: 100vh;
			overflow-y: auto;
			background: #FFFFFF;
			// left: 100px;
			// top: 50px;
			position: relative;
			z-index: 999;
			cursor: pointer;

			.text {
				color: #000000;
				font-size: 14px;
				font-weight: normal;
				line-height: 52px;
				// height: 56px;
				height: auto;
				overflow: hidden;
				user-select: none;
				&:hover{
					background: rgba(64, 158, 255, .1) !important;
				}
			}

			.titles {
				padding: 0 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.icons {
					margin-left: 4px;
					font-size: 14px;
					color: #000000;
					font-weight: 600;
					transition: all .3s;
				}

				.transition {
					transform: rotate(90deg);
				}
			}

			.childtext {
				padding: 0 30px;
				font-size: 14px;
				color: #666;
				background: #f5faff;
				&:hover{
					background: rgba(64, 158, 255, .03) !important;
				}
			}

			.towmenu {

				// font-weight: bold;
				div {
					text-align: left !important;
					font-weight: normal;
					color: #000000;
					;
				}
			}

			.navactive {
				color: #1989fa !important;
				background: rgba(64, 158, 255, .18) !important;
				border-right: 2px solid #1989fa;
				position: relative;
				font-weight: bold;
			}
		}
	}
</style>
