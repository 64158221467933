<template>
	<div id="recom">
		<router-view />
	</div>
</template>

<script>
import { eltips } from '@/util/util.js';
export default {
	name: 'recom',
	data() {
		return {
			keep:0,
		};
	},
	methods:{
		
	}
};
</script>



<style scoped lang="less">
#recom{
	min-height: calc(100vh - 110px);
	position: relative;
}
</style>
