import store from '@/store/index.js'
import Vue from 'vue'
import recom from '../layout/components/recom.vue'

// 转component字段的字符串为import
export function changeroute(routers) {
	let routerTree = []
	routerTree = assembleroute(routers,1)
	sessionStorage.setItem('routestext',JSON.stringify(routerTree))
	store.commit('changeRouteadds', 1)
	return routerTree
}

//递归组装路由
function assembleroute (routers,level){
	let routerTree = []
	
	routers.forEach(item=>{
		let obj = {
			path: item.path,
			name: item.vue_name,
			meta:{
				title:item.title,
				layout:item.layout,
				router_name:item.router_name,
				icon:item.icon,
				level:level,
				props:item.props,
				type:item.type
			}
		}
		
		if(item.props){
			obj.meta.props = item.props
		}
		
		if(item.component == 'Layout'){
			Vue.set(obj, 'component', recom)
		}else{
			Vue.set(obj, 'component', (resolve) => require([`@/views/${item.component}`],resolve))
		}
				
		if(item._child&&item._child.length>0){
			obj.children = assembleroute(item._child,level+1)
		}
		
		routerTree.push(obj)
	})
	return routerTree
}